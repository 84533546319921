<template>
  <AuthFormContainer title="Password Reset">
    <div class="reset-form">

      <div>

        <CNInputPassword
          :model-value="formData.password"
          :read-only-on-init="true"
          :invalid="!!validationErrors.password || passwordInvalid"
          @update:model-value="v => formData.password = v"
          label="Enter New Password"
          @blur="passwordTouched = true"
          @change="handleChangeField('password', $event)"
        />

        <div class="reset-form__validations">
          <div
            v-for="validator in passValidators"
            :key="validator.title"
            :class="[`reset-form__validations-item_${ validator.validator ? 'success' : 'failed' }`]"
            class="reset-form__validations-item">

            <div class="reset-form__validations-label" :style="{ 'background': passwordTouched ? '' : 'silver' }">
              <CIcon name="cilCheckAlt" />
            </div>

            <span :style="{ 'color': passwordTouched ? '' : 'black' }">{{ validator.title }}</span>

          </div>
        </div>
      </div>

      <div>
        <CNInputPassword
          :model-value="formData.password_confirmation"
          :read-only-on-init="true"
          :invalid="!!validationErrors.password_confirmation"
          @update:model-value="v => formData.password_confirmation = v"
          label="Confirm Password"
          @blur="handleChangeField('password_confirmation', $event)"
        />

        <CFormText v-if="validationErrors.password_confirmation" :style="`color: ${validationErrors.password_confirmation && 'red'}`">
          {{ validationErrors.password_confirmation }}
        </CFormText>
      </div>

      <CButton class="reset-form__submit" :disabled="submitLoading" color="primary" @click="handleSubmit">
        <CSpinner v-if="submitLoading" size="sm" class="reset-form__spinner" />
        <span v-else>Reset Password</span>
      </CButton>

      <div class="reset-form__question">
        <span class="reset-form__text">Don't have an account yet?</span>
        <router-link :to="{ name: 'SignUp' }" class="reset-form__link">Create Account</router-link>
      </div>

    </div>
  </AuthFormContainer>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { parseError } from '@/utils/api'
import AuthFormContainer from "@/components/AuthFormContainer.vue";
import CNInputPassword from "@/components/ui/CNInputPassword/CNInputPassword.vue";

export default {
  name: 'RecoveryPassword',
  components: {CNInputPassword, AuthFormContainer},
  inject: ['toast'],
  data() {
    return {
      submitLoading: false,

      formData: {
        password: '',
        password_confirmation: '',
      },

      passwordTouched: false,

      validator: {},
      validationErrors: {}
    }
  },
  created() {
    this.checkInputData()
    this.setRules()
  },
  computed: {
    passValidators() {
      return [
        {
          title: 'Contains lowercase and uppercase letters',
          validator: /[a-z]/.test(this.formData.password) && /[A-Z]/.test(this.formData.password)
        },
        {
          title: 'Contains at least one number',
          validator: /\d/.test(this.formData.password) },
        {
          title: 'Contains at least one symbol',
          validator: /[!@#$%^&*()_{}\[\]/'`~<>\-+]/.test(this.formData.password)
        },
        {
          title: 'At least 8 characters',
          validator: this.formData.password.length >= 8
        }
      ]
    },
    passwordInvalid() {
      if (!this.passwordTouched) return false

      return !this.passValidators[0].validator ||
        !this.passValidators[1].validator ||
        !this.passValidators[2].validator ||
        !this.passValidators[3].validator
    },
  },
  methods: {
    decodeURI(string) {
      return decodeURIComponent(string)
    },
    handleSubmit() {
      const validationResult = this.validator.validateAll(this.formData)

      if (!validationResult.hasErrors) {
        this.submitLoading = true

        const formData = {
          ...this.formData,
          ...this.$route.query,
          email: this.decodeURI(this.$route.query?.email),
        }
        this.$http.auth
          .resetPassword(formData)
          .then(() => {
            this.toast('success', 'The password has been changed successfully')
            this.$router.push({ name: 'Login' })
          })
          .catch((err) => {
            err = parseError(err)
            if (err.status === 422) {
              this.validationErrors = err.validationMessages
              if (err.validationMessages.email) {
                this.toast('success', err.validationMessages.email)
              } else if (err.validationMessages.token) {
                this.toast('success'. err.validationMessages.token)
              }
            } else {
              this.toast('error', err.message)
            }
          })
          .finally(() => {
            this.submitLoading = false
          })
      } else {
        this.validationErrors = validationResult.validationErrors
      }
    },
    handleChangeField(field, event) {
      this.validationErrors[field] = this.validator.validate(
        field,
        event
      )
    },
    checkInputData() {
      const token = this.$route.query?.token
      const email = this.decodeURI(this.$route.query?.email)

      if (!token || !email) {
        this.$router.push({ name: 'Login' })
      }
    },
    setRules() {
      this.validator = validator({
        password: [rules.required],
        password_confirmation: [
          rules.required,
          rules.passwordConfirmation(() => this.formData.password),
        ],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__submit {
    max-width: 170px;
  }

  &__link {
    font-size: 14px;
    color: #0068AD;
    text-decoration: underline;
    cursor: pointer;
  }

  &__text {
    color: #1C262F;
    font-size: 14px;
  }

  &__question {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__validations {
    margin-top: 12px;

    &:has(> &-label_success) {
      color: green;
    }

    &-item {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 12px;

      &_success {
        color: #0BBD99;

        & .reset-form__validations-label {
          background: #0BBD99;
        }
      }

      &_failed {
        color: #FF1F26;

        & .reset-form__validations-label {
          background: #FF1F26;
        }
      }
    }

    &-label {
      height: 10px;
      width: 10px;
      border-radius: 200px;
      background: silver;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        height: 8px !important;
        width: 8px !important;
        color: white;
      }
    }
  }
}



.password__container {
  max-width: 300px;
  width: 100%;
  @media (max-width: 600px) {
    max-width: 100%;
  }
}
.password__title {
  text-align: center;
  font-weight: 700;
  font-size: 28px;
}
</style>

<style>
.is-invalid + .password-visibility-toggle {
  margin-right: 1.7rem;
}
.password-visibility-toggle {
  width: 20px;
  object-fit: contain;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  cursor: pointer;
}
</style>
